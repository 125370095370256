<template>
  <page-view>
    <div class="child_header">
      <a-row :gutter="48" class="row">
        <a-col :lg="5" :md="12" :sm="24" :style="{ paddingLeft: '0', paddingRight: '0' }">
          <div class="filter_item">
            <span>网站名称</span>
            <a-input placeholder="网站名称" class="filter_input" v-model.trim="queryParam.siteTitle" />
          </div>
        </a-col>

        <a-col :lg="5" :md="12" :sm="24" :style="{ paddingLeft: '0', paddingRight: '0' }">
          <div class="filter_item">
            <span>域名</span>
            <a-input placeholder="域名" class="filter_input" v-model.trim="queryParam.host" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ paddingLeft: '0', paddingRight: '0' }">
          <div class="filter_item">
            <span>用户ID</span>
            <a-input placeholder="用户ID" class="filter_input" v-model.trim="queryParam.uid" />
          </div>
        </a-col>
        <a-col :lg="3" :md="12" :sm="24" :style="{ paddingLeft: '0', paddingRight: '0' }">
          <div class="filter_item">
            <a-button
              type="primary"
              class="query_btn"
              @click="
                () => {
                  $refs.table.refresh(false);
                }
              "
              >查询</a-button
            >
            <a-button @click="resetQuery">重置</a-button>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="child_table_wrapper">
      <s-table ref="table" rowKey="id" :columns="columns" :data="dataSource">
        <!-- 序号 -->
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
        <!-- 用户id -->
        <template slot="uid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span class="uid_fat" @click="copyInfo(record.uid)" @mouseover="handleMouseover(record)" @mouseout="handleMouseout(record)">
              {{ text.substring(0, 4) + '……' + text.substring(28) }}
              <span class="uid_child" v-show="record.showid1">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>
        <!-- logo -->
        <template slot="logo" slot-scope="text">
          <img v-if="text" :src="text" @click="() => (preview = { show: true, image: text })" style="width: 45px;height: 45px;" />
        </template>
        <!-- 微信二维码 -->
        <template slot="wechat" slot-scope="text">
          <img v-if="text" :src="text" @click="() => (preview = { show: true, image: text })" style="width: 45px;height: 45px;" />
        </template>
        <!-- 成本价 -->
        <template slot="plus" slot-scope="text">
          <span style="color:#F93244">{{ text }}</span>
        </template>
        <!-- 推广提成 -->
        <template slot="inviter" slot-scope="text">
          <span style="color:#F93244">{{ text }}</span>
        </template>
        <!-- 推广人ID -->

        <template slot="inviterUid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span class="uid_fat" @click="copyInfo(record.inviterUid)" @mouseover="handleMouseover2(record)" @mouseout="handleMouseout2(record)">
              {{ text.substring(0, 4) + '……' + text.substring(28) }}
              <span class="uid_child" v-show="record.showid2">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>
        <!-- 操作 -->
        <template slot="operation" slot-scope="text, record">
          -
          <!-- <a @click="showUpdateModal(record)">修改</a> -->
        </template>
      </s-table>
      <a-modal title="修改" :width="480" :visible="update.show" @ok="handleUpdate" @cancel="() => (update.show = false)">
        <span style="margin-right:8px">成本价:</span>
        <a-input-number :min="0" placeholder="成本价" v-model="update.record.plus" />
      </a-modal>
    </div>
  </page-view>
</template>

<script>
import { PageView } from '@/layouts';
import { STable } from '@/components';
import { oemInviterList, oemInviterUpdate } from '@/api';
import { formatDate } from '@/utils/util';

export default {
  components: {
    PageView,
    STable
  },
  data() {
    return {
      update: { show: false, record: {} },
      queryParam: {},
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '用户ID',
          dataIndex: 'uid',
          scopedSlots: { customRender: 'uid' }
        },
        {
          title: '网站logo',
          dataIndex: 'logo',
          scopedSlots: { customRender: 'logo' }
        },
        {
          title: '域名',
          dataIndex: 'host'
        },

        {
          title: '网站名称',
          dataIndex: 'siteTitle'
        },
        {
          title: 'QQ号码',
          dataIndex: 'qq'
        },

        {
          title: '微信二维码',
          dataIndex: 'wechat',
          scopedSlots: { customRender: 'wechat' }
        },
        {
          title: '卖出价',
          dataIndex: 'sell'
        },
        {
          title: '成本价',
          dataIndex: 'plus',
          scopedSlots: { customRender: 'plus' }
        },
        {
          title: '下级溢价',
          dataIndex: 'part'
        },
        {
          title: '推广提1成',
          dataIndex: 'inviter',
          scopedSlots: { customRender: 'inviter' }
        },
        {
          title: '商品加价',
          dataIndex: 'goodsPart'
        },
        {
          title: '推广人ID',
          dataIndex: 'inviterUid',
          scopedSlots: { customRender: 'inviterUid' }
        },

        {
          title: '修改时间',
          dataIndex: 'updateTime',
          customRender: formatDate
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'operation' }
        }
      ],

      dataSource: (parameter) => {
        return oemInviterList(Object.assign(parameter, this.queryParam)).then((res) => {
          res.data.list.forEach((item) => {
            item.showid1 = false;
            item.showid2 = false;
          });
          return res.data;
        });
      }
    };
  },

  methods: {
    handleMouseover(record) {
      record.showid1 = true;
    },
    handleMouseout(record) {
      record.showid1 = false;
    },
    handleMouseover2(record) {
      record.showid2 = true;
    },
    handleMouseout2(record) {
      record.showid2 = false;
    },

    showUpdateModal(record) {
      this.update.show = true;
      this.update.record = JSON.parse(JSON.stringify(record));
    },
    handleUpdate() {
      const data = {
        id: this.update.record.id,
        part: this.update.record.plus
      };
      console.log(data);
      oemInviterUpdate(data).then((res) => {
        console.log(res);
        if (res.success) {
          this.$message.success('修改成功');
          this.update.show = false;
          this.update.record.plus = '';
          this.$refs.table.refresh(false);
        } else {
          this.$message.error(msg);
        }
      });
    },
    resetQuery() {
      this.queryParam = {};
      this.$refs.table.refresh(false);
    }
  }
};
</script>

<style lang="less" scoped>
.child_header {
  padding: 24px 40px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  .row {
    margin-left: 0 !important;
  }

  .filter_item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .filter_input {
      width: 240px;
      height: 32px;
      margin-left: 6px;
    }
    .query_btn {
      margin-right: 12px;
    }
  }
}
.child_table_wrapper {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  font-family: PingFang SC;
  padding: 24px;
}

.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    transition: all 2s;
  }
}
</style>
